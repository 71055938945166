<template>
    <div class="content-sub-detail">
            <router-link v-if= "contentDetails.category" class="active-pointer" :to="actRedirectionMetaTag('category', (configCTTag) || ((contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category) || (contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)), (configCTTag) || ((contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category) || (contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)))">
              {{ categoryShow($t(CTATitle) || $t(contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category)) }}
            </router-link>
            <p v-if="contentDetails.genre">|</p>
            <!-- <router-link v-if="contentDetails.genre" class="active-pointer" :to="actRedirectionMetaTag('genre', removeSpace(contentDetails.defaultgenre), (configCTTag) || ((contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category) || (contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)))">
              {{ $t(`${contentDetails.genre}`) }}
            </router-link> -->
            <p  v-if="contentDetails.genre">
              {{ $t(`${contentDetails.genre}`) }}
            </p>
            <p v-if="contentDetails.subgenre && contentDetails.subgenre.length > 0 ">|</p>
            <!-- <router-link  v-if="contentDetails.subgenre && contentDetails.subgenre.length > 0 "  class="active-pointer" :to="actRedirectionMetaTag('genre', removeSpace(contentDetails.subgenre[0]), (configCTTag) || ((contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category) || (contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)))">
              {{ $t(`${contentDetails.subgenre[0]}`) }}
            </router-link> -->
            <p  v-if="contentDetails.subgenre && contentDetails.subgenre.length > 0 ">
              {{ $t(`${contentDetails.subgenre[0]}`) }}
            </p>

            <p v-if="contentDetails.contentlanguage[0]">|</p>

            <p  v-if="contentDetails.contentlanguage[0]">
              {{
                contentDetails.contentlanguage[0]
                  ? $t(`${contentDetails.contentlanguage[0]}`)
                  : ""
              }}
            </p>

            <p v-if="contentDetails.productionyear">|</p>

            <!-- <router-link  v-if="contentDetails.productionyear" class="active-pointer" :to="actRedirectionMetaTag('year', contentDetails.productionyear, (configCTTag) || ((contentDetails.category == 'TVSHOW' ? 'SERIES' : contentDetails.category) || (contentDetails.objecttype == 'CONTENT' ? 'MOVIE' : contentDetails.objecttype)))">
              {{ contentDetails.productionyear }}
            </router-link > -->
            <p v-if="contentDetails.productionyear">{{ contentDetails.productionyear }}</p>

            <p v-if="contentDetails.pgrating">|</p>

            <div dir="ltr" class="pg-rating" v-if="contentDetails.pgrating">
              <p class="camel-case">{{ contentDetails.pgrating }}</p>
            </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import Utility from "@/mixins/Utility.js";

export default {
    props:['configCTTag', 'contentDetails', 'disableLanguage', 'CTATitle'],
    computed: {
    ...mapGetters([
      "appConfig"
    ]),
    },
    data() {
    return {
        mobileMenuList: []
    }
    },
    created() {

    },
    mounted() {
        
        this.appConfig.mobileMenuList.map((item, i) => {
            this.mobileMenuList.push(item.id.toLowerCase());
        });
    },
    methods: {

        actRedirectionMetaTag(type, tag, category){
            let section = (tag.toString()).toLowerCase();
            section == 'movie' ? section = 'movies' : section;
            section == 'series' || section == 'natak' ? section = 'shows' : section;
            section == 'music' ? section = 'songs' : section;
            section == 'shorts' ? section = "shortbytes" : section;
            let currentLanguage = this.getCurrentLanguageUrlBase();
            if(this.mobileMenuList.includes(section)){
                switch(section){
                    case "home":
                    return ({name: tag, params: {lang: currentLanguage }});
                    case "movies":
                    return ({name: "section-screen", params: { section: "movies", lang: currentLanguage }});
                    case "shows":
                    return ({name: "section-screen", params: { section: "shows", lang: currentLanguage }});
                    case "songs":
                    return ({name: "section-screen-video", params: { section: 'music', lang: currentLanguage }});
                    case "devotional":
                    return ({name: "section-screen-devotional", params: { section: 'devotional', lang: currentLanguage }});
                    case "shortbytes":
                    return ({name: "section-screen-bytes", params: { section: 'shortbytes', lang: currentLanguage }});
                    default:
                    return ({name: "section-screen", params: { section: section, lang: currentLanguage }});
                }
            } else {
                let ctg = (category.toString()).toLowerCase();


                if (ctg == 'series' || ctg == 'natak' || ctg == 'shows') { 
                    return({ name: "screen-meta-redirection", params: { objecttype: 'marathi-shows', value: section , type: type, lang: currentLanguage } });
                } else if (ctg == 'movie' || ctg == 'movies' || ctg == 'content') {
                    return({ name: "screen-meta-redirection", params: { objecttype: 'marathi-movies', value: section , type: type, lang: currentLanguage } });
                } else if (ctg == 'devotional') {
                    return({ name: "screen-meta-redirection", params: { objecttype: 'marathi-devotional-videos', value: section , type: type, lang: currentLanguage } });
                } else if (ctg == 'music') {
                    return({ name: "screen-meta-redirection", params: { objecttype: 'marathi-video-songs', value: section , type: type, lang: currentLanguage } });
                } else if (ctg == 'shorts') {
                    return({ name: "screen-meta-redirection", params: { objecttype: 'marathi-short-video-bytes', value: section , type: type, lang: currentLanguage } });
                } else if (ctg == 'kids') {
                    return({ name: "screen-meta-redirection", params: { objecttype: 'marathi-kids', value: section , type: type, lang: currentLanguage } });
                } else {
                    return({ name: "screen-meta-redirection", params: { objecttype: (this.contentDetails.objecttype).toLowerCase(), value: section , type: type, lang: currentLanguage } });
                }
                
            }
           
            },
        removeSpace(item) {
            return item.replace(" ", "");
        },
        categoryShow(category) {
          if(category == 'Movie') {
            return 'Movies';
          }
          return category;
        }
    },
    mixins: [Utility],

}

</script>
<style lang="scss">
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  @import "./detailPage.scss";
</style>